import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

// パンくずリスト
import Breadcrumbs from "../../components/Common/Breadcrumb";

// Redux アクション
import { getGoodsMaster, registerGoodsMaster } from "../../store/goods-master/actions";

// 共通コンポーネント
import ErrorMessage from "../Common/ErrorMessage";
import LoadingOverlay from "../Common/LoadingOverlay";
import CommonComponent from "../CommonComponent";

const GoodsMaster = () => {
  const dispatch = useDispatch();

  // Redux の state
  const {
    goodsMasterData,
    loading,
    error,
    registerLoading,
    registerError,
    registerSuccess,
  } = useSelector((state) => state.goodsMasterReducer);

  const [goodsCategory, setGoodsCategory] = useState(null);
  const [locId, setLoc] = useState("");
  const [locGroup, setLocGroup] = useState(null);

  // フィルター用ステート（不整合種別フィルター）
  const [filterType, setFilterType] = useState("ALL");
  // 新たに「一致しているレコードも表示する」チェックボックスの状態
  const [includeNormal, setIncludeNormal] = useState(false);

  // モーダル管理
  const [warningModal, setWarningModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);

  // データ取得：includeNormal の値を API 呼び出しに渡す
  useEffect(() => {
    dispatch(getGoodsMaster(goodsCategory, locId, includeNormal));
  }, [dispatch, goodsCategory, locId, includeNormal]);

  // 商品マスタ登録
  const handleRegister = (item) => {
    if (!item.goods_name || !item.stock_goods_no) {
      setWarningModal(true);
      return;
    }
    const itemWithLocation = {
      ...item,
      location_id: locId,
    };
    dispatch(registerGoodsMaster(itemWithLocation));
  };

  // 登録成功時の処理
  useEffect(() => {
    if (registerSuccess) {
      setSuccessModal(true);
      dispatch(getGoodsMaster(goodsCategory, locId, includeNormal));
    }
  }, [registerSuccess, dispatch, goodsCategory, locId, includeNormal]);

  // ★ 差分がある箇所に黄色の網掛けを行うスタイリング関数 ★

  const getMasterNoStyle = (item) => {
    if (!item.master_goods_no && item.stock_goods_no) {
      return { backgroundColor: "#ffffcc" };
    }
    if (item.master_goods_no && item.stock_goods_no && item.master_goods_no !== item.stock_goods_no) {
      return { backgroundColor: "#ffffcc" };
    }
    return {};
  };

  const getStockNoStyle = (item) => {
    if (!item.stock_goods_no && item.master_goods_no) {
      return { backgroundColor: "#ffffcc" };
    }
    if (item.stock_goods_no && item.master_goods_no && item.stock_goods_no !== item.master_goods_no) {
      return { backgroundColor: "#ffffcc" };
    }
    return {};
  };

  const getMasterNameStyle = (item) => {
    if (!item.master_goods_name && item.stock_goods_name) {
      return { backgroundColor: "#ffffcc" };
    }
    if (item.master_goods_name && item.stock_goods_name && item.master_goods_name !== item.stock_goods_name) {
      return { backgroundColor: "#ffffcc" };
    }
    return {};
  };

  const getStockNameStyle = (item) => {
    if (!item.stock_goods_name && item.master_goods_name) {
      return { backgroundColor: "#ffffcc" };
    }
    if (item.stock_goods_name && item.master_goods_name && item.stock_goods_name !== item.master_goods_name) {
      return { backgroundColor: "#ffffcc" };
    }
    return {};
  };

  // フィルター後の配列
  const filteredResults = (() => {
    if (!goodsMasterData?.results) return [];
    if (!Array.isArray(goodsMasterData.results)) return [];

    switch (filterType) {
      case "商品番号不一致":
        return goodsMasterData.results.filter((item) => item.mismatch_type === "商品番号不一致");
      case "商品番号未登録":
        return goodsMasterData.results.filter((item) => item.mismatch_type === "商品番号未登録");
      case "商品名不一致":
        return goodsMasterData.results.filter((item) => item.mismatch_type === "商品名不一致");
      case "商品マスタ未登録":
        return goodsMasterData.results.filter((item) => item.mismatch_type === "商品マスタ未登録");
      case "ALL":
      default:
        return goodsMasterData.results;
    }
  })();

  // ソート処理：IPC名（昇順）、その次にカラムNo（昇順）で並べ替え
  const sortedResults = filteredResults.slice().sort((a, b) => {
    const ipcA = (a.ipc_name || "").toLowerCase();
    const ipcB = (b.ipc_name || "").toLowerCase();
    if (ipcA < ipcB) return -1;
    if (ipcA > ipcB) return 1;
    return (Number(a.column_no) || 0) - (Number(b.column_no) || 0);
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            maintitle="Home"
            title="システム管理"
            breadcrumbItem="商品情報設定の確認"
          />

          <ErrorMessage error={error || registerError} />
          <LoadingOverlay isLoading={loading || registerLoading} />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <h4>クラウドおよびマスタ情報の整合確認</h4>
                  <p className="text-muted">
                    下記は商品マスタとクラウドデータ（在庫）との不整合一覧です。<br />
                    商品名・品番が正しいか確認のうえ、必要に応じて登録してください。
                  </p>

                  <CommonComponent
                    showGoodsCategory={false}
                    showDate={false}
                    showAll={true}
                    goodsCategory={goodsCategory}
                    setGoodsCategory={setGoodsCategory}
                    locId={locId}
                    setLoc={setLoc}
                    setLocGroup={setLocGroup}
                    showLocGroup={false}
                    past_loc={false}
                  />

                  <Row className="mt-2">
                    <Col sm={12} md={6}>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">フィルター：</span>
                        <select
                          value={filterType}
                          onChange={(e) => setFilterType(e.target.value)}
                          className="form-control w-auto"
                        >
                          <option value="ALL">すべて</option>
                          <option value="商品番号不一致">商品番号不一致</option>
                          <option value="商品番号未登録">商品番号未登録</option>
                          <option value="商品名不一致">商品名不一致</option>
                          <option value="商品マスタ未登録">商品マスタ未登録</option>
                        </select>
                      </div>
                      {/* チェックボックスの追加 */}
                      <FormGroup check className="mt-2">
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={includeNormal}
                            onChange={(e) => setIncludeNormal(e.target.checked)}
                          />{" "}
                          一致しているレコードも表示する
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>

                  {/* 以下、不整合一覧の表示 */}
                  <Row className="mt-3">
                    <Col lg={12}>
                      <h5>不整合一覧</h5>
                      {sortedResults.length > 0 ? (
                        <div style={{ overflowX: "auto" }}>
                          <div
                            style={{
                              backgroundColor: "#fff",
                              border: "1px solid #000",
                              minWidth: "900px",
                            }}
                          >
                            {/* ヘッダ行 */}
                            <div
                              className="d-flex font-weight-bold"
                              style={{
                                borderBottom: "1px solid #000",
                                backgroundColor: "#e6f2ff",
                              }}
                            >
                              <div style={{ width: "10%", borderRight: "1px solid #000", padding: "8px" }}>
                                種別
                              </div>
                              <div style={{ width: "10%", borderRight: "1px solid #000", padding: "8px" }}>
                                IPC番号
                              </div>
                              <div style={{ width: "15%", borderRight: "1px solid #000", padding: "8px" }}>
                                IPC名(ロケーション)
                              </div>
                              <div style={{ width: "5%", borderRight: "1px solid #000", padding: "8px" }}>
                                カラム
                              </div>
                              <div style={{ width: "10%", borderRight: "1px solid #000", padding: "8px" }}>
                                クラウド商品番号
                              </div>
                              <div style={{ width: "20%", borderRight: "1px solid #000", padding: "8px" }}>
                                クラウド商品名
                              </div>
                              <div style={{ width: "10%", borderRight: "1px solid #000", padding: "8px" }}>
                                マスタ商品番号
                              </div>
                              <div style={{ width: "20%", borderRight: "1px solid #000", padding: "8px" }}>
                                マスタ商品名
                              </div>
                              <div style={{ width: "10%", padding: "8px" }}>
                                操作
                              </div>
                            </div>
                            {/* データ行 */}
                            {sortedResults.map((item, idx) => {
                              const canRegister = item.mismatch_type === "商品マスタ未登録";
                              return (
                                <div
                                  className="d-flex"
                                  key={idx}
                                  style={{ borderBottom: "1px solid #000" }}
                                >
                                  <div
                                    style={{
                                      width: "10%",
                                      borderRight: "1px solid #000",
                                      padding: "8px",
                                      ...(item.mismatch_type
                                        ? { color: "#FF0000", fontWeight: "bold", textTransform: "uppercase" }
                                        : {}),
                                    }}
                                  >
                                    {item.mismatch_type || "-"}
                                  </div>
                                  <div style={{ width: "10%", borderRight: "1px solid #000", padding: "8px" }}>
                                    {item.ipc_no || "-"}
                                  </div>
                                  <div style={{ width: "15%", borderRight: "1px solid #000", padding: "8px" }}>
                                    {item.ipc_name}
                                    {item.loc_name ? ` (${item.loc_name})` : ""}
                                  </div>
                                  <div style={{ width: "5%", borderRight: "1px solid #000", padding: "8px" }}>
                                    {item.column_no || "-"}
                                  </div>
                                  <div
                                    style={{
                                      width: "10%",
                                      borderRight: "1px solid #000",
                                      padding: "8px",
                                      ...getStockNoStyle(item),
                                    }}
                                  >
                                    {item.stock_goods_no || "-"}
                                  </div>
                                  <div
                                    style={{
                                      width: "20%",
                                      borderRight: "1px solid #000",
                                      padding: "8px",
                                      ...getStockNameStyle(item),
                                    }}
                                  >
                                    {item.stock_goods_name || "-"}
                                  </div>
                                  <div
                                    style={{
                                      width: "10%",
                                      borderRight: "1px solid #000",
                                      padding: "8px",
                                      ...getMasterNoStyle(item),
                                    }}
                                  >
                                    {item.master_goods_no || "-"}
                                  </div>
                                  <div
                                    style={{
                                      width: "20%",
                                      borderRight: "1px solid #000",
                                      padding: "8px",
                                      ...getMasterNameStyle(item),
                                    }}
                                  >
                                    {item.master_goods_name || "-"}
                                  </div>
                                  <div style={{ width: "10%", padding: "8px" }}>
                                    <Button
                                      color={canRegister ? "primary" : "secondary"}
                                      size="sm"
                                      disabled={!canRegister}
                                      onClick={() => handleRegister(item)}
                                    >
                                      商品マスタ登録
                                    </Button>
                                  </div>
                                </div>
                              );
                            })}
                            <div style={{ padding: "8px" }}>
                              <p className="mb-0">総件数: {sortedResults.length}件</p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <p>不整合データはありません</p>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        {/* 警告モーダル */}
        <Modal isOpen={warningModal} toggle={() => setWarningModal(!warningModal)}>
          <ModalHeader toggle={() => setWarningModal(!warningModal)}>
            警告
          </ModalHeader>
          <ModalBody>
            商品名または在庫の商品番号が未入力のため、登録できません。<br />
            データを正しく入力してください。
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setWarningModal(false)}>
              閉じる
            </Button>
          </ModalFooter>
        </Modal>

        {/* 登録成功モーダル */}
        <Modal isOpen={successModal} toggle={() => setSuccessModal(!successModal)}>
          <ModalHeader toggle={() => setSuccessModal(!successModal)}>
            成功
          </ModalHeader>
          <ModalBody>
            商品マスタの登録が正常に完了しました。
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => setSuccessModal(false)}>
              閉じる
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default GoodsMaster;
