import { startOfMonth, subDays, subMonths, subYears } from "date-fns"
import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap"
import {
  fetchShelfByIpcAndColumn,
  updateExpirationDateRequest,
} from "../../store/shelfs/actions"
import { fetchGraphData } from "../../store/stocks/actions"
import { useLocation, useNavigate } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { StyledButton4 } from "../../components/StyledComponents"
import { fetchIpcsData } from "../../store/marketing/common/actions"
import ErrorMessage from "../Common/ErrorMessage"
import LoadingOverlay from "../Common/LoadingOverlay"

const Shelfs = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const locData = useSelector(state => state.marketingCommonReducer.locData)
  const isLoading1 = useSelector(state => state.marketingCommonReducer.loading)
  const graphData = useSelector(state => state.stocksReducer.graphData)
  const results = graphData && graphData.data ? graphData.data.results : []
  const isLoadingGraphData = useSelector(state => state.stocksReducer.loading)
  const columns = useSelector(
    state => state.stocksReducer.graphData?.data?.results || []
  )
  const shelfData = useSelector(state => state.shelfsReducer.shelf)
  const isLoading = useSelector(state => state.shelfsReducer.loading)

  const [isSaved, setIsSaved] = useState(false)

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const locId = queryParams.get("loc_id")
  const columnNo = queryParams.get("column_no")
  const isFromStocks = queryParams.get("isStock") === "1"

  const [selectedLoc, setSelectedLoc] = useState(null)
  const [selectedShelf, setSelectedShelf] = useState(null)
  const [selectedColumn, setSelectedColumn] = useState(null)
  const [expiryDate, setExpiryDate] = useState("")
  const [modal, setModal] = useState(false)
  const [searchQuery, setSearchQuery] = useState("")
  const [goodsCategory, setGoodsCategory] = useState(null)
  const [vendor_id, setVendor] = useState(null)
  const [timePeriod, setTimePeriod] = useState("時")
  const [currentDate, setCurrentDate] = useState(new Date())
  const [selectedShelves, setSelectedShelves] = useState([])
  const [bulkEndData, setBulkEndDate] = useState("")
  const [bulkExpiryDate, setBulkExpiryDate] = useState("")
  const [selectedRows, setSelectedRows] = useState({})
  const [columnMode, setColumnMode] = useState(false)

  // コラム一覧モーダルの状態
  const [columnsModal, setIpcColumnModal] = useState(false)

  // 「近い賞味期限のロケーションのみ表示」用の状態
  const [nearExpiredFilter, setNearExpiredFilter] = useState(false)

  // isWithinOneWeek を利用する前に定義する
  const isWithinOneWeek = dateStr => {
    if (!dateStr) return false
    const today = new Date()
    const oneWeekLater = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + 7
    )
    const targetDate = new Date(
      dateStr.replace(/[年月]/g, "/").replace("日", "")
    )
    return targetDate <= oneWeekLater
  }

  useEffect(() => {
    dispatch(fetchIpcsData(2, null))
  }, [dispatch])

  // メインテーブル用のソート設定
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  })

  const handleSort = key => {
    let direction = "ascending"
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending"
    }
    setSortConfig({ key, direction })
  }

  const sortedMachines = useMemo(() => {
    let sortableItems = [...(locData?.data?.results || [])]
    sortableItems = sortableItems
      .filter(loc => loc.loc_code !== null)
      .filter(loc => {
        const locCode = loc.loc_code?.toString().toLowerCase() || ""
        const locName = loc.loc_name?.toLowerCase() || ""
        return (
          locCode.includes(searchQuery.toLowerCase()) ||
          locName.includes(searchQuery.toLowerCase())
        )
      })
      .filter(loc =>
        nearExpiredFilter
          ? (loc.oldest_expiry_date && isWithinOneWeek(loc.oldest_expiry_date))
          : true
      )
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] === null)
          return sortConfig.direction === "ascending" ? -1 : 1
        if (b[sortConfig.key] === null)
          return sortConfig.direction === "ascending" ? 1 : -1
        if (a[sortConfig.key] < b[sortConfig.key])
          return sortConfig.direction === "ascending" ? -1 : 1
        if (a[sortConfig.key] > b[sortConfig.key])
          return sortConfig.direction === "ascending" ? 1 : -1
        return 0
      })
    }
    return sortableItems
  }, [locData, searchQuery, nearExpiredFilter, sortConfig])

  const previousDate = useMemo(() => {
    if (timePeriod === "月") {
      return startOfMonth(subYears(currentDate, 1))
    } else if (timePeriod === "日") {
      return startOfMonth(currentDate)
    } else if (timePeriod === "時") {
      return subDays(currentDate, 1)
    } else if (timePeriod === "3か月") {
      return startOfMonth(subMonths(currentDate, 3))
    }
  }, [timePeriod, currentDate])

  useEffect(() => {
    if (selectedShelf) {
      setExpiryDate(selectedShelf.expiry_date || "")
      setModal(true)
    }
  }, [selectedShelf])

  useEffect(() => {
    if (shelfData) {
      setSelectedShelf(shelfData)
    }
  }, [shelfData])

  const formatDate = dateString => {
    if (!dateString) {
      return "未設定"
    }
    return dateString.split("T")[0]
  }

  const formatDateTime = isoString => {
    if (!isoString) {
      return "未設定"
    }
    const date = new Date(isoString)
    return date
      .toLocaleString("ja-JP", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      })
      .replace(/\//g, "-")
  }

  const resetSelection = () => {
    setSelectedRows({})
  }

  const toggleModal = () => {
    setModal(!modal)
    resetSelection()
  }

  const handleRowSelect = (index, isChecked) => {
    setSelectedRows({ ...selectedRows, [index]: isChecked })
  }

  // handleIpcClick を復活させる
  const handleIpcClick = loc => {
    setSelectedLoc(loc)
    dispatch(fetchGraphData(goodsCategory, loc.loc_id, timePeriod, currentDate, previousDate))
    resetSelection()
    setIpcColumnModal(true)
  }

  // 商品に関連する棚情報取得
  const handleProductClick = product => {
    const locationId = locId || selectedLoc.loc_id
    dispatch(fetchShelfByIpcAndColumn(locationId, product.goods_name))
    if (!shelfData || !shelfData.data) {
      const emptyShelves = Array(product.full_stock).fill({ isEmpty: true })
      setSelectedShelf({ data: { results: emptyShelves } })
    } else {
      const fullStock = product.full_stock
      const currentStock = product.current_stock
      const emptyRows = fullStock - currentStock
      const newShelves = [
        ...shelfData.data.results,
        ...Array(emptyRows).fill({ isEmpty: true }),
      ]
      setSelectedShelf({ ...shelfData, data: { results: newShelves } })
    }
    setSelectedColumn(product)
    setModal(true)
  }

  // 棚情報レンダリング関数
  const renderShelfData = (shelf, index, totalStock, currentStock) => {
    if (index >= currentStock) {
      return null
    }
    const isStockEmpty = index >= currentStock
    const replenishDate = isStockEmpty
      ? ""
      : shelf
        ? formatDateTime(shelf.start_date)
        : "未設定"
    const endDate = isStockEmpty
      ? ""
      : shelf
        ? formatDate(shelf.end_date)
        : "未設定"
    const expiryDate = isStockEmpty
      ? ""
      : shelf
        ? formatDate(shelf.expiry_date)
        : "未設定"

    return (
      <tr key={index}>
        <td>
          <input
            type="checkbox"
            checked={!!selectedRows[index]}
            onChange={e => handleRowSelect(index, e.target.checked)}
            disabled={isStockEmpty}
          />
        </td>
        <td>{replenishDate}</td>
        <td
          style={
            shelf && shelf.expiry_date && isWithinOneWeek(shelf.expiry_date)
              ? { fontWeight: "bold", color: "red" }
              : {}
          }
        >
          {expiryDate}
        </td>
        <td
          style={
            shelf && shelf.end_date && isWithinOneWeek(shelf.end_date)
              ? { fontWeight: "bold", color: "blue" }
              : {}
          }
        >
          {endDate}
        </td>
      </tr>
    )
  }

  // モーダル外のテーブルレンダリング
  const renderTableBody = () => {
    if (!selectedColumn) {
      return null
    }
    const totalRows = selectedColumn.full_stock
    const currentStock = selectedColumn.current_stock
    const shelfRows =
      selectedShelf && selectedShelf.data ? selectedShelf.data.results : []
    let rows = []
    for (let i = 0; i < totalRows; i++) {
      rows.push(renderShelfData(shelfRows[i], i, totalRows, currentStock))
    }
    return rows
  }

  const saveChanges = () => {
    let updates = []
    const totalStock = selectedColumn.full_stock
    Object.keys(selectedRows).forEach(index => {
      if (selectedRows[index]) {
        if (shelfData && index < shelfData.data.results.length) {
          const shelf = shelfData.data.results[index]
          updates.push({
            shelf_id: shelf.shelf_id,
            end_date: bulkEndData,
            expiry_date: bulkExpiryDate,
            ipc_no: selectedLoc.ipc_no,
            loc_id: selectedLoc.loc_id,
            column_no: selectedColumn.goods_name,
          })
        } else if (index < totalStock) {
          updates.push({
            shelf_id: 0,
            end_date: bulkEndData,
            expiry_date: bulkExpiryDate,
            ipc_no: selectedLoc.ipc_no,
            loc_id: selectedLoc.loc_id,
            column_no: selectedColumn.goods_name,
          })
        }
      }
    })
    if (updates.length > 0) {
      dispatch(updateExpirationDateRequest(updates))
      setIsSaved(true)
    } else {
      console.log("更新する行が選択されていません")
    }
  }

  const isAllStockZero = () => {
    return (
      selectedShelf &&
      selectedShelf.data &&
      selectedShelf.data.results.every(shelf => shelf.current_stock === 0)
    )
  }

  const [selectAll, setSelectAll] = useState(false)

  const handleSelectAll = isChecked => {
    setSelectAll(isChecked)
    const newSelectedRows = {}
    const totalStock = selectedColumn.full_stock
    const currentStock = selectedColumn.current_stock
    for (let i = 0; i < totalStock; i++) {
      newSelectedRows[i] = i < currentStock ? isChecked : false
    }
    setSelectedRows(newSelectedRows)
  }

  // モーダル内テーブル用のソート設定
  const [sortConfigModal, setSortConfigModal] = useState({
    key: null,
    direction: "ascending",
  })

  const handleSortModal = key => {
    let direction = "ascending"
    if (sortConfigModal.key === key && sortConfigModal.direction === "ascending") {
      direction = "descending"
    }
    setSortConfigModal({ key, direction })
  }

  const sortedColumns = useMemo(() => {
    let sortableItems = [...columns]
    if (sortConfigModal.key !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfigModal.key] === null)
          return sortConfigModal.direction === "ascending" ? -1 : 1
        if (b[sortConfigModal.key] === null)
          return sortConfigModal.direction === "ascending" ? 1 : -1
        if (a[sortConfigModal.key] < b[sortConfigModal.key])
          return sortConfigModal.direction === "ascending" ? -1 : 1
        if (a[sortConfigModal.key] > b[sortConfigModal.key])
          return sortConfigModal.direction === "ascending" ? 1 : -1
        return 0
      })
    }
    return sortableItems
  }, [columns, sortConfigModal])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid style={{ maxWidth: "95%" }}>
          <Breadcrumbs title="ホーム" breadcrumbItem="滞留・賞味期限" />
          <ErrorMessage />
          <LoadingOverlay isLoading={isLoading} />
          <LoadingOverlay isLoading={isLoading1} />
          <LoadingOverlay isLoading={isLoadingGraphData} />
          <Card>
            <CardBody>
              <CardTitle>ロケーション一覧</CardTitle>
              <input
                type="search"
                value={searchQuery}
                onChange={e => setSearchQuery(e.target.value)}
                placeholder="ロケーションID または ロケーション名で絞り込み"
                style={{ width: "95%", margin: "10px", padding: "4px" }}
              />
              <div style={{ display: "flex" }}>
                <label style={{ marginLeft: "10px" }}>
                  <input
                    type="checkbox"
                    checked={nearExpiredFilter}
                    onChange={() => setNearExpiredFilter(!nearExpiredFilter)}
                  />
                  近い賞味期限のロケーションのみ表示
                </label>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <span style={{ color: "red", fontWeight: "bold", fontSize: "16px" }}>
                  ！
                </span>{" "}
                - 賞味期限
                <span
                  style={{
                    color: "blue",
                    fontWeight: "bold",
                    fontSize: "16px",
                    marginLeft: "10px",
                  }}
                >
                  ！
                </span>{" "}
                - 滞留期限
              </div>
              <Table striped responsive>
                <thead>
                  <tr>
                    <th className="hide-on-mobile" onClick={() => handleSort("loc_code")}>
                      ロケーションID{" "}
                      {sortConfig.key === "loc_code" &&
                        (sortConfig.direction === "ascending" ? "↑" : "↓")}
                    </th>
                    <th onClick={() => handleSort("loc_name")}>
                      ロケーション名{" "}
                      {sortConfig.key === "loc_name" &&
                        (sortConfig.direction === "ascending" ? "↑" : "↓")}
                    </th>
                    <th onClick={() => handleSort("oldest_expiry_date")}>
                      直近の賞味期限{" "}
                      {sortConfig.key === "oldest_expiry_date" &&
                        (sortConfig.direction === "ascending" ? "↑" : "↓")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sortedMachines.length > 0 ? (
                    sortedMachines.map(loc => (
                      <tr key={loc.loc_id} onClick={() => handleIpcClick(loc)}>
                        <td className="hide-on-mobile">{loc.loc_code}</td>
                        <td>
                          {loc.near_expired === 1 ? (
                            <span style={{ color: "red", fontWeight: "bold", fontSize: "16px" }}>
                              ！
                            </span>
                          ) : (
                            <span style={{ display: "inline-block", width: "16px", height: "16px" }} />
                          )}
                          {loc.loc_name}
                        </td>
                        <td
                          style={
                            loc.oldest_expiry_date && isWithinOneWeek(loc.oldest_expiry_date)
                              ? { fontWeight: "bold", color: "red" }
                              : {}
                          }
                        >
                          {loc.oldest_expiry_date
                            ? loc.oldest_expiry_date.replace("年", "-").replace("月", "-").replace("日", "")
                            : "データなし"}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3" style={{ textAlign: "center" }}>
                        自販機のデータがありません
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </CardBody>
          </Card>
          {/* コラム一覧モーダル */}
          <Modal isOpen={columnsModal} toggle={() => setIpcColumnModal(!columnsModal)}>
            <ModalHeader toggle={() => setIpcColumnModal(!columnsModal)}>
              コラム一覧
              <div style={{ marginTop: "10px", marginLeft: "1rem", fontSize: "85%" }}>
                {selectedLoc ? `ロケ名： ${selectedLoc.loc_name}` : ""}
              </div>
              <div style={{ marginTop: "5px", marginLeft: "1rem", fontSize: "85%" }}>
                {selectedLoc ? `ロケコード： ${selectedLoc.loc_code}` : ""}
              </div>
            </ModalHeader>
            <ModalBody>
              <Table striped responsive>
                <thead>
                  <tr>
                    <th onClick={() => handleSortModal("goods_name")}>
                      コラム{" "}
                      {sortConfigModal.key === "goods_name" &&
                        (sortConfigModal.direction === "ascending" ? "↑" : "↓")}
                    </th>
                    <th className="hide-on-mobile">在庫/満ﾀﾝ</th>
                    <th onClick={() => handleSortModal("oldest_end_date")} className="shelfs-date">
                      滞留期日{" "}
                      {sortConfigModal.key === "oldest_end_date" &&
                        (sortConfigModal.direction === "ascending" ? "↑" : "↓")}
                    </th>
                    <th onClick={() => handleSortModal("oldest_expiry_date")}>
                      直近の賞味期限{" "}
                      {sortConfigModal.key === "oldest_expiry_date" &&
                        (sortConfigModal.direction === "ascending" ? "↑" : "↓")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sortedColumns.length > 0 ? (
                    sortedColumns.map((column, index) => (
                      <tr key={index}>
                        <td>{column.goods_name}</td>
                        <td className="hide-on-mobile">
                          {column.current_stock}/{column.full_stock}
                        </td>
                        <td
                          style={
                            column.oldest_end_date && isWithinOneWeek(column.oldest_end_date)
                              ? { fontWeight: "bold", color: "blue" }
                              : {}
                          }
                        >
                          {column.oldest_end_date
                            ? column.oldest_end_date.replace("年", "-").replace("月", "-").replace("日", "")
                            : "データなし"}
                        </td>
                        <td
                          style={
                            column.oldest_expiry_date && isWithinOneWeek(column.oldest_expiry_date)
                              ? { fontWeight: "bold", color: "red" }
                              : {}
                          }
                        >
                          {column.oldest_expiry_date
                            ? column.oldest_expiry_date.replace("年", "-").replace("月", "-").replace("日", "")
                            : "データなし"}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4" style={{ textAlign: "center" }}>
                        コラムのデータがありません
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              {isFromStocks && (
                <Button
                  color="purple"
                  className="btn btn-secondary mt-4 w-100"
                  onClick={() => navigate(-1)}
                >
                  戻る
                </Button>
              )}
              <Button
                color="secondary"
                className="btn btn-secondary mt-4 w-100"
                onClick={() => setIpcColumnModal(!columnsModal)}
              >
                閉じる
              </Button>
            </ModalBody>
          </Modal>
          <Modal isOpen={modal} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>
              <div>賞味期限設定</div>
              <div style={{ marginTop: "10px", marginLeft: "1rem", fontSize: "85%" }}>
                {selectedColumn
                  ? `商品名： ${selectedColumn.goods_name}`
                  : "商品名が利用できません"}{" "}
              </div>
              <div style={{ marginTop: "8px", marginLeft: "1rem", fontSize: "85%" }}>
                {selectedLoc ? `ロケ名： ${selectedLoc.ipc_name}` : ""}
              </div>
              <div style={{ marginTop: "5px", marginLeft: "1rem", fontSize: "85%" }}>
                {selectedLoc ? `ロケコード： ${selectedLoc.loc_code}` : ""}
              </div>
            </ModalHeader>
            <ModalBody>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: "15px",
                }}
              >
                <div style={{ flex: 1 }}>
                  一括設定 - 賞味期限:
                  <Input
                    type="date"
                    value={bulkExpiryDate}
                    onChange={e => setBulkExpiryDate(e.target.value)}
                    style={{ fontSize: "12px", width: "100%" }}
                  />
                </div>
                <div style={{ flex: 1, marginRight: "10px" }}>
                  一括設定 - 滞留期日:
                  <Input
                    type="date"
                    value={bulkEndData}
                    onChange={e => setBulkEndData(e.target.value)}
                    style={{ fontSize: "12px", width: "100%" }}
                  />
                </div>
              </div>
              {/* テーブル */}
              <div style={{ maxHeight: "300px", overflowY: "auto" }}>
                <Table striped responsive>
                  <thead>
                    <tr>
                      <th style={{ width: "auto" }}>
                        <input
                          type="checkbox"
                          checked={selectAll}
                          onChange={e => handleSelectAll(e.target.checked)}
                        />
                      </th>
                      <th style={{ width: "auto" }}>補充日</th>
                      <th style={{ width: "auto" }}>賞味期限</th>
                      <th style={{ width: "auto" }}>滞留期日</th>
                    </tr>
                  </thead>
                  <tbody>{renderTableBody()}</tbody>
                </Table>
              </div>
            </ModalBody>
            <ModalFooter style={{ display: "flex", justifyContent: "space-around" }}>
              <StyledButton4
                color="secondary"
                onClick={() => {
                  setIsSaved(false)
                  toggleModal()
                }}
                style={{ flex: 1, marginRight: "10px" }}
              >
                キャンセル
              </StyledButton4>
              <StyledButton4
                color="primary"
                onClick={saveChanges}
                style={{ flex: 1 }}
              >
                保存
              </StyledButton4>
            </ModalFooter>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Shelfs
