// src/components/SidebarContent.js

import PropTypes from "prop-types"
import React, { useCallback, useEffect, useRef, useState } from "react"

// Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import withRouter from "components/Common/withRouter"
import MetisMenu from "metismenujs"
import { Link, useLocation } from "react-router-dom"

// i18n
import { withTranslation } from "react-i18next"

// Redux
import { useDispatch, useSelector } from "react-redux"
import { fetchIncompleteDeliveryPlansCountRequest } from "../../store/deliveryPlan/actions"

// CSS
import "./SidebarContent.css" // バッジ用のカスタムCSSをインポート

const SidebarContent = props => {
  const location = useLocation()
  const ref = useRef()
  const path = location.pathname

  // ローカルストレージからauthUserを取得し、パースしてuserオブジェクトを作成
  const [user, setUser] = useState(null)

  useEffect(() => {
    const authUser = localStorage.getItem("authUser")
    if (authUser) {
      setUser(JSON.parse(authUser))
    }
  }, [])

  const dispatch = useDispatch()

  // incompleteCount はオブジェクト { pendingCount, loadingCount, deliveryCount, totalCount } が入っている
  const incompleteCount = useSelector(state => state.deliveryPlanReducer.incompleteDeliveryPlansCount) || {}
  const isLoading = useSelector(state => state.deliveryPlanReducer.loading)
  const error = useSelector(state => state.deliveryPlanReducer.error)

  // オブジェクトから必要な4つのキーを分割代入
  const {
    pendingCount = 0,
    loadingCount = 0,
    deliveryCount = 0,
    totalCount = 0,
  } = incompleteCount

  useEffect(() => {
    dispatch(fetchIncompleteDeliveryPlansCountRequest())
  }, [dispatch])

  useEffect(() => {
    console.log("incompleteCount:", incompleteCount)
  }, [incompleteCount])

  const tToggle = () => {
    var body = document.body
    if (window.screen.width <= 992) {
      body.classList.toggle("sidebar-enable")
    } else {
      body.classList.toggle("sidebar-enable")
    }
  }

  const activateParentDropdown = useCallback(item => {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag
        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") // a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }, [])

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i]
      const parent = items[i].parentElement

      if (item && item.classList.contains("active")) {
        item.classList.remove("active")
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.length && parent.childNodes[1]
            ? parent.childNodes[1]
            : null
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show")
        }

        parent.classList.remove("mm-active")
        const parent2 = parent.parentElement

        if (parent2) {
          parent2.classList.remove("mm-show")

          const parent3 = parent2.parentElement
          if (parent3) {
            parent3.classList.remove("mm-active") // li
            parent3.childNodes[0].classList.remove("mm-active")

            const parent4 = parent3.parentElement // ul
            if (parent4) {
              parent4.classList.remove("mm-show") // ul
              const parent5 = parent4.parentElement
              if (parent5) {
                parent5.classList.remove("mm-show") // li
                parent5.childNodes[0].classList.remove("mm-active") // a tag
              }
            }
          }
        }
      }
    }
  }

  const activeMenu = useCallback(() => {
    const pathName = location.pathname
    const fullPath = pathName
    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    removeActivation(items)

    for (let i = 0; i < items.length; ++i) {
      if (fullPath === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  }, [path, activateParentDropdown])

  const handleMenuItemClick = e => {
    tToggle()
  }

  useEffect(() => {
    ref.current.recalculate()
  }, [])

  useEffect(() => {
    new MetisMenu("#side-menu")
    activeMenu()
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
    activeMenu()
  }, [activeMenu])

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function getVisibilityClassNameLevel0(user) {
    if (user && typeof user.level !== "undefined" && user.level !== null) {
      return user.level === 0 ? "" : "hidden"
    } else {
      return ""
    }
  }

  function getVisibilityClassNameLevel1(user) {
    if (user && typeof user.level !== "undefined" && user.level !== null) {
      return user.level === 1 ? "" : "hidden"
    } else {
      return ""
    }
  }

  function getVisibilityClassNameUnderLevel1(user) {
    if (user && typeof user.level !== "undefined" && user.level !== null) {
      return user.level <= 1 ? "" : "hidden"
    } else {
      return ""
    }
  }

  function getVisibilityClassNameCompany1(user) {
    if (user && typeof user.company !== "undefined" && user.company !== null) {
      return user.company == 1 ? "" : "hidden"
    } else {
      return "hidden"
    }
  }

  function getVisibilityClassNameForLevels0124(user) {
    if (user && typeof user.level !== "undefined" && user.level !== null) {
      // レベルが0、1、2、4の場合は空文字列（表示）、それ以外の場合は"hidden"を返す
      return user.level === 0 ||
        user.level === 1 ||
        user.level === 2 ||
        user.level === 4
        ? ""
        : "hidden"
    } else {
      return ""
    }
  }

  function getVisibilityClassNameLevel3(user) {
    if (user && typeof user.level !== "undefined" && user.level !== null) {
      return user.level === 3 ? "" : "hidden"
    } else {
      return ""
    }
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("メニュー")} </li>
            <li>
              <Link to="/dashboard" className="waves-effect">
                <i className="ti-dashboard"></i>
                <span>{props.t("ダッシュボード")}</span>
              </Link>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ti-layout-grid2-alt"></i>
                <span>{props.t("商品管理")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/stocks" onClick={tToggle}>
                    {props.t("在庫確認")}{" "}
                  </Link>
                </li>
                <li className={getVisibilityClassNameForLevels0124(user)}>
                  <Link to="/shelfs" onClick={tToggle}>
                    {props.t("滞留・賞味期限")}{" "}
                  </Link>
                </li>
                <li className={getVisibilityClassNameForLevels0124(user)}>
                  <Link to="/sold-out" onClick={tToggle}>
                    {props.t("売切一覧")}{" "}
                  </Link>
                </li>
                <li className={getVisibilityClassNameForLevels0124(user)}>
                  <Link to="/warehouse-stock" onClick={tToggle}>
                    {props.t("倉庫在庫")}{" "}
                  </Link>
                </li>
              </ul>
            </li>

            <li className={getVisibilityClassNameForLevels0124(user)}>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ti-truck"></i>
                <span>{props.t("配送計画")}</span>
                {/* 配送計画 (メインメニュー) の合計バッジ */}
                {totalCount > 0 && (
                  <span className="badge badge-custom ms-2">
                    {totalCount}
                  </span>
                )}
                {error && (
                  <span className="badge badge-error ms-2" title={error}>
                    !
                  </span>
                )}
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/delivery-plan/create" onClick={tToggle}>
                    {props.t("新規作成")}
                  </Link>
                </li>
                <li>
                  <Link to="/delivery-plan/show" onClick={tToggle}>
                    {props.t("計画一覧")}
                    {/* 未実施 (pendingCount) */}
                    {pendingCount > 0 && (
                      <span className="badge badge-custom ms-2">
                        {pendingCount}
                      </span>
                    )}
                    {error && (
                      <span className="badge badge-error ms-2" title={error}>
                        !
                      </span>
                    )}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/delivery-plan/loading"
                    onClick={tToggle}
                    className={
                      location.pathname === "/delivery-plan/loading"
                        ? "active"
                        : ""
                    }
                  >
                    {props.t("商品積込")}
                    {/* 新規の積込 (loadingCount) */}
                    {loadingCount > 0 && (
                      <span className="badge badge-custom ms-2">
                        {loadingCount}
                      </span>
                    )}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/delivery-plan/delivery"
                    onClick={tToggle}
                    className={
                      location.pathname === "/delivery-plan/delivery"
                        ? "active"
                        : ""
                    }
                  >
                    {props.t("配送実行")}
                    {/* 新規の配送実行 (deliveryCount) */}
                    {deliveryCount > 0 && (
                      <span className="badge badge-custom ms-2">
                        {deliveryCount}
                      </span>
                    )}
                  </Link>
                </li>
              </ul>
            </li>
            <li></li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ti-world"></i>
                <span>{props.t("ロケーション分析")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/flows" onClick={tToggle}>
                    {props.t("人流カウント")}
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ti-bar-chart"></i>
                <span>{props.t("売上分析")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/sales" onClick={tToggle}>
                    {props.t("販売履歴")}
                  </Link>
                </li>
                <li>
                  <Link to="/sales-daily" onClick={tToggle}>
                    {props.t("日別売上")}
                  </Link>
                </li>
                <li>
                  <Link to="/marketing/volume" onClick={tToggle}>
                    {props.t("販売数量推移")}
                  </Link>
                </li>
                <li>
                  <Link to="/marketing/time" onClick={tToggle}>
                    {props.t("時間帯別")}
                  </Link>
                </li>
                <li>
                  <Link to="/marketing/customer" onClick={tToggle}>
                    {props.t("購入者層")}
                  </Link>
                </li>
                <li>
                  <Link to="/salesAll" onClick={tToggle}>
                    {props.t("ロケーション全体")}
                  </Link>
                </li>
                <li>
                  <Link to="/salesLoc" onClick={tToggle}>
                    {props.t("ロケーション毎")}
                  </Link>
                </li>
              </ul>
            </li>
            <li className={getVisibilityClassNameForLevels0124(user)}>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="ti-settings"></i>
                <span>{props.t("システム管理")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li className={getVisibilityClassNameUnderLevel1(user)}>
                  <Link to="/users" onClick={tToggle}>
                    {props.t("ユーザ管理")}{" "}
                  </Link>
                </li>
                <li className={getVisibilityClassNameForLevels0124(user)}>
                  <Link to="/goods" onClick={tToggle}>
                    {props.t("商品管理")}{" "}
                  </Link>
                </li>
                <li className={getVisibilityClassNameLevel1(user)}>
                  <Link to="/categories" onClick={tToggle}>
                    {props.t("商品カテゴリー管理")}{" "}
                  </Link>
                </li>
                <li className={getVisibilityClassNameLevel0(user)}>
                  <Link to="/customers" onClick={tToggle}>
                    {props.t("顧客属性変更")}{" "}
                  </Link>
                </li>
                <li className={getVisibilityClassNameLevel0(user)}>
                  <Link to="/customers2" onClick={tToggle}>
                    {props.t("売上別顧客属性変更")}{" "}
                  </Link>
                </li>
                <li className={getVisibilityClassNameLevel0(user)}>
                  <Link to="/cameras" onClick={tToggle}>
                    {props.t("Prime-Eye 機器管理")}{" "}
                  </Link>
                </li>
                <li className={getVisibilityClassNameLevel0(user)}>
                  <Link to="/camera-info" onClick={tToggle}>
                    {props.t("Prime-Eye 状態管理")}{" "}
                  </Link>
                </li>
                <li className={getVisibilityClassNameLevel0(user)}>
                  <Link to="/companies" onClick={tToggle}>
                    {props.t("企業情報管理")}{" "}
                  </Link>
                </li>
                <li className={getVisibilityClassNameForLevels0124(user)}>
                  <Link to="/ipcs" onClick={tToggle}>
                    {props.t("自販機情報管理")}{" "}
                  </Link>
                </li>
                <li className={getVisibilityClassNameLevel1(user)}>
                  <Link to="/location" onClick={tToggle}>
                    {props.t("ロケーション管理")}{" "}
                  </Link>
                </li>
                <li className={getVisibilityClassNameForLevels0124(user)}>
                  <Link to="/warehouse" onClick={tToggle}>
                    {props.t("倉庫管理")}{" "}
                  </Link>
                </li>
                <li className={getVisibilityClassNameForLevels0124(user)}>
                  <Link to="/vehicle" onClick={tToggle}>
                    {props.t("配送車管理")}{" "}
                  </Link>
                </li>
                <li className={getVisibilityClassNameLevel0(user)}>
                  <Link to="/stocks-history" onClick={tToggle}>
                    {props.t("在庫履歴")}{" "}
                  </Link>
                </li>
                <li className={getVisibilityClassNameLevel0(user)}>
                  <Link to="/ipc-error" onClick={tToggle}>
                    {props.t("障害情報")}{" "}
                  </Link>
                </li>
                <li className={getVisibilityClassNameLevel0(user)}>
                  <Link to="/syscheck" onClick={tToggle}>
                    {props.t("システムチェック")}{" "}
                  </Link>
                </li>
                <li className={getVisibilityClassNameForLevels0124(user)}>
                  <Link to="/goods-master" onClick={tToggle}>
                    {props.t("商品情報設定の確認")}{" "}
                  </Link>
                </li>                
              </ul>
            </li>
            <li>
              <Link
                to="/J-Prime_manual.pdf"
                className="waves-effect"
                target="_blank"
              >
                <i className="ti-file"></i>
                <span>{props.t("操作マニュアル")}</span>
              </Link>
            </li>
            <li>
              <Link
                to="/J-Prime_terms.pdf"
                className="waves-effect"
                target="_blank"
              >
                <i className="ti-write"></i>
                <span>{props.t("利用規約")}</span>
              </Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
