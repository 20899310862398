import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  FormGroup,
  Label,
  Input,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import ErrorMessage from "../Common/ErrorMessage"
import LoadingOverlay from "../Common/LoadingOverlay"

import { fetchVehicles, fetchVehicleStock } from "../../store/vehicle/actions"
import {
  fetchItemsForLoading,
  updateDeliveryPlanRequest,
} from "../../store/deliveryPlan/actions"

const DeliveryPlanVehicle = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const { startLocationName, deliveryPlan = {} } = location.state || {}
  const displayStartLocationName = startLocationName || "Unknown Location"

  const vehicles = useSelector(state => state.vehicleReducer.vehicles || [])
  const items = useSelector(state => state.deliveryPlanReducer.items || [])
  const vehicleStocks = useSelector(state => state.vehicleReducer.vehicleStock || [])
  const isLoading1 = useSelector(state => state.vehicleReducer.loading)
  const isLoading2 = useSelector(state => state.deliveryPlanReducer.loading)

  const [selectedVehicle, setSelectedVehicle] = useState(
    deliveryPlan?.plan?.vehicle_id >= 0 ? deliveryPlan.plan.vehicle_id : ""
  )
  const [showItems, setShowItems] = useState(false)

  // 個別アイテムごとの「補充数」を state 管理
  const [quantities, setQuantities] = useState({})
  // 同名商品まとめ用
  const [groupedItems, setGroupedItems] = useState([])
  // ロケーションごと
  const [groupedByLocationItems, setGroupedByLocationItems] = useState({})
  // 「確認」状態
  const [confirmedItems, setConfirmedItems] = useState({})

  const [modal, setModal] = useState(false)
  const [alertModal, setAlertModal] = useState(false)
  const [vehicleStocksModal, setVehicleStocksModal] = useState(false)
  const [completionModal, setCompletionModal] = useState(false)
  const [activeTab, setActiveTab] = useState("1")
  const [selectedIpcNos, setSelectedIpcNos] = useState([])

  useEffect(() => {
    if (!deliveryPlan.id) {
      navigate("/delivery-plan/select")
      return
    }
    dispatch(fetchVehicles())
  }, [dispatch, deliveryPlan.id, navigate, location.state])

  useEffect(() => {
    if (!items.length) return

    // ロケーション毎にまとめる
    const byLoc = {}
    items.forEach(item => {
      if (!byLoc[item.loc_name]) {
        byLoc[item.loc_name] = []
      }
      byLoc[item.loc_name].push(item)
    })

    // 商品名毎にまとめる
    const byName = []
    items.forEach(item => {
      const existing = byName.find(g => g.name === item.name)
      if (existing) {
        existing.itemIds.push(item.id)
        existing.current_quantity += item.current_quantity || 0
        existing.max_quantity += item.max_quantity || 0
      } else {
        byName.push({
          name: item.name,
          itemIds: [item.id],
          current_quantity: item.current_quantity || 0,
          max_quantity: item.max_quantity || 0,
        })
      }
    })

    setGroupedByLocationItems(byLoc)
    setGroupedItems(byName)
  }, [items])

  // showItems → 補充数の初期化
  useEffect(() => {
    if (showItems) {
      const outOfStock = items.some(item => !item.in_warehouse_stock)
      if (outOfStock) {
        setAlertModal(true)
      }
      const initQ = {}
      items.forEach(it => {
        initQ[it.id] = it.needed_quantity
      })
      setQuantities(initQ)
    }
  }, [items, showItems])

  // 配送車選択
  const handleSelectVehicle = e => {
    setSelectedVehicle(e.target.value)
  }

  // 商品を読み込む（積込モード）
  const handleLoadItems = () => {
    if (!selectedVehicle) return
    const updatedPlan = {
      ...deliveryPlan,
      vehicle_id: parseInt(selectedVehicle, 10),
      status: 2,
      id: parseInt(deliveryPlan.id, 10),
    }
    dispatch(updateDeliveryPlanRequest({ delivery_plan: updatedPlan }))
    dispatch(
      fetchItemsForLoading({ deliveryPlanId: deliveryPlan.id, vehicleId: selectedVehicle })
    )
    setShowItems(true)
  }

  // + / - ボタン付きの増減（個別タブ）
  const incrementQuantity = itemId => {
    setQuantities(prev => {
      const newVal = (prev[itemId] || 0) + 1
      return { ...prev, [itemId]: newVal }
    })
  }

  const decrementQuantity = itemId => {
    setQuantities(prev => {
      const newVal = Math.max(0, (prev[itemId] || 0) - 1)
      return { ...prev, [itemId]: newVal }
    })
  }

  const handleQuantityChange = (itemId, value) => {
    const val = Math.max(0, parseInt(value, 10) || 0)
    setQuantities(prev => ({
      ...prev,
      [itemId]: val,
    }))
  }

  // まとめタブ（商品名単位）での + / - 操作
  const handleSummaryIncrement = g => {
    const currentSum = g.itemIds.reduce((acc, id) => acc + (quantities[id] || 0), 0)
    distributeSummaryNeeded(g, currentSum + 1)
  }

  const handleSummaryDecrement = g => {
    const currentSum = g.itemIds.reduce((acc, id) => acc + (quantities[id] || 0), 0)
    distributeSummaryNeeded(g, currentSum - 1)
  }

  const distributeSummaryNeeded = (g, summaryValue) => {
    const totalNeeded = Math.max(0, parseInt(summaryValue, 10) || 0)
    const newQuantities = { ...quantities }

    if (!g.itemIds.length) return

    // 例: 先頭アイテムにすべて割り当て、残りは 0
    newQuantities[g.itemIds[0]] = totalNeeded
    for (let i = 1; i < g.itemIds.length; i++) {
      newQuantities[g.itemIds[i]] = 0
    }

    setQuantities(newQuantities)
  }

  const handleSummaryInputChange = (g, e) => {
    distributeSummaryNeeded(g, e.target.value)
  }

  // 「全て確認」ボタン
  const handleConfirmAll = tabId => {
    if (tabId === "1") {
      const newConfirmed = { ...confirmedItems }
      groupedItems.forEach(g => {
        g.itemIds.forEach(id => {
          newConfirmed[`1_${id}`] = true
        })
      })
      setConfirmedItems(newConfirmed)
    } else if (tabId === "2") {
      const newConfirmed = { ...confirmedItems }
      Object.values(groupedByLocationItems).forEach(locItems => {
        locItems.forEach(item => {
          newConfirmed[`2_${item.id}`] = true
        })
      })
      setConfirmedItems(newConfirmed)
    }
  }

  const handleConfirmItem = (tabId, itemId) => {
    setConfirmedItems(prev => ({
      ...prev,
      [`${tabId}_${itemId}`]: true,
    }))
  }

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  // 積込完了 → store 更新
  const handleCompleteLoading = () => {
    const updatedItems = items.map(it => ({
      ...it,
      needed_quantity: quantities[it.id] || 0,
    }))
    const updatedPlan = {
      ...deliveryPlan,
      vehicle_id: parseInt(selectedVehicle, 10),
      status: 3,
      items: updatedItems,
      id: parseInt(deliveryPlan.id, 10),
    }
    dispatch(updateDeliveryPlanRequest({ delivery_plan: updatedPlan }))
    setCompletionModal(true)
  }

  const handleCloseCompletionModal = () => {
    setCompletionModal(false)
    navigate("/delivery-plan/delivery")
  }

  // キャンセル
  const handleCancel = () => {
    const updatedPlan = {
      ...deliveryPlan,
      status: 1,
      id: parseInt(deliveryPlan.id, 10),
    }
    dispatch(updateDeliveryPlanRequest({ delivery_plan: updatedPlan }))
    navigate("/delivery-plan/select", {
      state: { deliveryPlan: updatedPlan },
    })
  }

  // IPC No を表示するモーダル
  const handleItemClick = ipcNosArray => {
    setSelectedIpcNos(ipcNosArray)
    setModal(true)
  }

  const toggleModal = () => setModal(!modal)

  // 倉庫在庫なし注意モーダル
  const toggleAlertModal = () => setAlertModal(!alertModal)

  // 配送車在庫モーダル
  const toggleVehicleStocksModal = () => {
    if (!vehicleStocksModal && selectedVehicle) {
      dispatch(fetchVehicleStock(selectedVehicle))
    }
    setVehicleStocksModal(!vehicleStocksModal)
  }

  // 一つでも確認済みなら「積込完了」ボタンを押せる（現行仕様）
  const allItemsConfirmed = Object.values(confirmedItems).some(val => val === true)

  if (!deliveryPlan.id) {
    return null
  }
  const planId = deliveryPlan.id

  return (
    <React.Fragment>
      <div className="page-content" style={{ backgroundColor: "#dcdcdc" }}>
        <Container fluid={true}>
          <Breadcrumbs
            maintitle="ホーム"
            title="補充計画"
            breadcrumbItem="配送車選択"
          />
          <ErrorMessage />
          <LoadingOverlay isLoading={isLoading1 || isLoading2} />

          <Row className="justify-content-center">
            <Col xs={12} md={8}>
              <Card className="border p-3">
                <CardBody>
                  <CardTitle tag="h4" className="text-center">
                    配送計画詳細
                  </CardTitle>
                  <hr />
                  <div
                    className="border p-3 mb-3"
                    style={{
                      backgroundColor: "#dcdcdc",
                      borderRadius: "5px",
                      padding: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          fontWeight: "bold",
                          fontSize: "1.2em",
                          margin: 0,
                        }}
                      >
                        計画No: {planId}
                      </p>
                      <p style={{ margin: 0 }}>
                        作成時間: {deliveryPlan?.created_at || "N/A"}
                      </p>
                    </div>
                  </div>

                  <div className="text-left">
                    <p>担当者: {deliveryPlan?.user_name || "N/A"}</p>
                    <p>開始日時: {deliveryPlan?.start_date || "N/A"}</p>
                  </div>

                  <CardTitle tag="h5">配送車選択</CardTitle>
                  <FormGroup>
                    <Label htmlFor="startLocationSelect">スタート地点</Label>
                    <Input
                      type="select"
                      id="startLocationSelect"
                      value={deliveryPlan.start_warehouse_id}
                      disabled
                    >
                      <option value={deliveryPlan.start_warehouse_id}>
                        {deliveryPlan.start_warehouse_name}
                      </option>
                    </Input>
                  </FormGroup>

                  <div className="text-center">↓</div>

                  <FormGroup>
                    <Label htmlFor="vehicleSelect">配送車を選択</Label>
                    <Input
                      type="select"
                      id="vehicleSelect"
                      value={selectedVehicle}
                      onChange={handleSelectVehicle}
                    >
                      <option value="">選択してください</option>
                      {vehicles.map(vehicle => (
                        <option key={vehicle.vehicle_id} value={vehicle.vehicle_id}>
                          {vehicle.vehicle_name}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>

                  <div className="text-center mt-4">
                    <Button
                      color="primary"
                      onClick={handleLoadItems}
                      disabled={!selectedVehicle}
                      style={{ width: "150px" }}
                    >
                      商品積込
                    </Button>{" "}
                    <Button
                      color="secondary"
                      onClick={toggleVehicleStocksModal}
                      disabled={!selectedVehicle}
                      style={{ width: "150px" }}
                    >
                      配送車在庫確認
                    </Button>
                  </div>

                  {showItems && (
                    <div className="mt-4">
                      <Nav tabs>
                        <NavItem>
                          <NavLink
                            className={classnames({ active: activeTab === "1" })}
                            onClick={() => toggleTab("1")}
                          >
                            まとめ
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({ active: activeTab === "2" })}
                            onClick={() => toggleTab("2")}
                          >
                            個別
                          </NavLink>
                        </NavItem>
                      </Nav>

                      <TabContent activeTab={activeTab}>
                        {/* ========== まとめタブ ========== */}
                        <TabPane tabId="1">
                          <h5 className="text-center mt-3">商品一覧（まとめ: MAX超えOK）</h5>
                          <Table responsive bordered>
                            <thead>
                              <tr>
                                <th>商品名</th>
                                <th>在庫/MAX</th>
                                <th>補充数</th>
                                <th>
                                  <Button
                                    color="link"
                                    onClick={() => handleConfirmAll("1")}
                                    style={{ textDecoration: "none", padding: 0 }}
                                  >
                                    全て確認
                                  </Button>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {groupedItems.map((g, idx) => {
                                const sumNeeded = g.itemIds.reduce(
                                  (acc, id) => acc + (quantities[id] || 0),
                                  0
                                )
                                const isAnyConfirmed = g.itemIds.some(
                                  id => confirmedItems[`1_${id}`]
                                )

                                return (
                                  <tr key={idx}>
                                    <td>{g.name}</td>
                                    <td style={{ textAlign: "center" }}>
                                      {g.current_quantity}/{g.max_quantity}
                                    </td>
                                    <td>
                                      {/* + / - ボタンを縦並びに配置 */}
                                      <div className="d-flex flex-column align-items-center">
                                        <Button
                                          size="sm"
                                          className="mb-1"
                                          onClick={() => handleSummaryIncrement(g)}
                                        >
                                          +
                                        </Button>
                                        <Input
                                          type="number"
                                          min="0"
                                          value={sumNeeded}
                                          onChange={e => handleSummaryInputChange(g, e)}
                                          className="mb-1 text-center"
                                          style={{ width: "60px" }}
                                        />
                                        <Button
                                          size="sm"
                                          onClick={() => handleSummaryDecrement(g)}
                                        >
                                          -
                                        </Button>
                                      </div>
                                    </td>
                                    <td>
                                      <Button
                                        color={isAnyConfirmed ? "success" : "warning"}
                                        style={{
                                          width: "80px",
                                          backgroundColor: isAnyConfirmed ? "green" : "orange",
                                        }}
                                        onClick={() => {
                                          // まとめて確認状態にする
                                          const newConfirmed = { ...confirmedItems }
                                          g.itemIds.forEach(id => {
                                            newConfirmed[`1_${id}`] = true
                                          })
                                          setConfirmedItems(newConfirmed)
                                        }}
                                      >
                                        {isAnyConfirmed ? "確認済み" : "確認"}
                                      </Button>
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                            <tfoot>
                              <tr>
                                <td style={{ fontWeight: "bold" }}>合計</td>
                                <td style={{ textAlign: "center", fontWeight: "bold" }}>
                                  {groupedItems.reduce(
                                    (acc, g) => acc + (g.current_quantity || 0),
                                    0
                                  )}
                                  /
                                  {groupedItems.reduce(
                                    (acc, g) => acc + (g.max_quantity || 0),
                                    0
                                  )}
                                </td>
                                <td colSpan="2"></td>
                              </tr>
                            </tfoot>
                          </Table>
                        </TabPane>

                        {/* ========== 個別タブ ========== */}
                        <TabPane tabId="2">
                          <h5 className="text-center mt-3">商品一覧（個別）</h5>
                          {Object.entries(groupedByLocationItems).map(([locName, locItems]) => {
                            const locCurrentTotal = locItems.reduce(
                              (acc, it) => acc + (it.current_quantity || 0),
                              0
                            )
                            const locNeededTotal = locItems.reduce(
                              (acc, it) => acc + (quantities[it.id] || 0),
                              0
                            )

                            return (
                              <div key={locName} className="mb-4">
                                <h6 style={{ fontWeight: "bold", marginTop: "1rem" }}>
                                  {locName}
                                </h6>
                                <Table responsive bordered>
                                  <thead>
                                    <tr>
                                      <th>商品名</th>
                                      <th>在庫</th>
                                      <th>補充数</th>
                                      <th>倉庫在庫</th>
                                      <th>
                                        <Button
                                          color="link"
                                          onClick={() => handleConfirmAll("2")}
                                          style={{ textDecoration: "none", padding: 0 }}
                                        >
                                          全て確認
                                        </Button>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {locItems.map(item => {
                                      const key = `2_${item.id}`
                                      const isConfirmed = !!confirmedItems[key]
                                      return (
                                        <tr key={item.id}>
                                          <td>
                                            {item.column_no}_{item.name}
                                          </td>
                                          <td style={{ textAlign: "center" }}>
                                            {item.current_quantity}/{item.max_quantity}
                                          </td>
                                          <td>
                                            {/* + / - ボタンを縦並びに配置 */}
                                            <div className="d-flex flex-column align-items-center">
                                              <Button
                                                size="sm"
                                                className="mb-1"
                                                onClick={() => incrementQuantity(item.id)}
                                              >
                                                +
                                              </Button>
                                              <Input
                                                type="number"
                                                min="0"
                                                value={quantities[item.id] || 0}
                                                onChange={e =>
                                                  handleQuantityChange(item.id, e.target.value)
                                                }
                                                className="mb-1 text-center"
                                                style={{ width: "50px" }}
                                              />
                                              <Button
                                                size="sm"
                                                onClick={() => decrementQuantity(item.id)}
                                              >
                                                -
                                              </Button>
                                            </div>
                                          </td>
                                          <td>
                                            {item.in_warehouse_stock ? "在庫あり" : "在庫なし"}
                                          </td>
                                          <td>
                                            <Button
                                              color={isConfirmed ? "success" : "warning"}
                                              style={{
                                                width: "70px",
                                                backgroundColor: isConfirmed ? "green" : "orange",
                                              }}
                                              onClick={() => handleConfirmItem("2", item.id)}
                                            >
                                              {isConfirmed ? "確認済み" : "確認"}
                                            </Button>
                                          </td>
                                        </tr>
                                      )
                                    })}
                                  </tbody>
                                  <tfoot>
                                    <tr>
                                      <td style={{ fontWeight: "bold" }}>小計</td>
                                      <td style={{ textAlign: "center", fontWeight: "bold" }}>
                                        {locCurrentTotal}
                                      </td>
                                      <td style={{ textAlign: "right", fontWeight: "bold" }}>
                                        {locNeededTotal}
                                      </td>
                                      <td colSpan="2"></td>
                                    </tr>
                                  </tfoot>
                                </Table>
                              </div>
                            )
                          })}
                        </TabPane>
                      </TabContent>

                      <div className="text-center mt-3">
                        <Button
                          color="success"
                          onClick={handleCompleteLoading}
                          disabled={!allItemsConfirmed}
                          style={{ width: "150px" }}
                        >
                          積込完了
                        </Button>
                      </div>
                    </div>
                  )}

                  <div className="text-center mt-3">
                    <Button color="danger" onClick={handleCancel} style={{ width: "150px" }}>
                      キャンセル
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* IPC No 表示用モーダル */}
      <Modal isOpen={modal} toggle={toggleModal} centered>
        <ModalHeader toggle={toggleModal}>IPC No</ModalHeader>
        <ModalBody>
          {selectedIpcNos.map((ipc, index) => (
            <div key={index}>
              {ipc.ipc_no}: {ipc.needed_quantity}個
            </div>
          ))}
        </ModalBody>
      </Modal>

      {/* 在庫なし注意モーダル */}
      <Modal isOpen={alertModal} toggle={toggleAlertModal} centered>
        <ModalHeader
          toggle={toggleAlertModal}
          style={{
            backgroundColor: "rgba(255, 69, 0, 0.7)",
            color: "white",
          }}
        >
          注意
        </ModalHeader>
        <ModalBody
          style={{
            backgroundColor: "rgba(255, 99, 71, 0.7)",
            color: "white",
            textAlign: "center",
            fontSize: "1.2em",
          }}
        >
          倉庫に在庫がない商品があります。責任者に確認してください。
        </ModalBody>
      </Modal>

      {/* 配送車在庫モーダル */}
      <Modal isOpen={vehicleStocksModal} toggle={toggleVehicleStocksModal} centered>
        <ModalHeader toggle={toggleVehicleStocksModal}>配送車在庫</ModalHeader>
        <ModalBody>
          <Table bordered responsive>
            <thead>
              <tr>
                <th>商品コード</th>
                <th>商品名</th>
                <th>数量</th>
              </tr>
            </thead>
            <tbody>
              {vehicleStocks.map(stock => (
                <tr key={stock.goods_id}>
                  <td>{stock.goods_no}</td>
                  <td>{stock.goods_name}</td>
                  <td>{stock.stock_count}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </ModalBody>
      </Modal>

      {/* 積込完了モーダル */}
      <Modal isOpen={completionModal} toggle={handleCloseCompletionModal}>
        <ModalHeader toggle={handleCloseCompletionModal}>積込完了</ModalHeader>
        <ModalBody>積込が完了しました。配送実行は別途実施してください。</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleCloseCompletionModal}>
            閉じる
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default DeliveryPlanVehicle
